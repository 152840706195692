<template>
  <v-container>
    <v-row>
      <v-col>
        <v-alert v-model="alert2" color="red" dismissible type="success">
          Error completed successfully!
        </v-alert>
        <v-alert v-model="alert3" color="success" dismissible type="success">
          Success Message!
        </v-alert>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-btn color="error" @click="showError1">Show Error 1</v-btn>
      </v-col>
      <v-col>
        <v-btn color="error" @click="showError2">Show Alert Error</v-btn>
      </v-col>
      <v-col>
        <v-btn color="success" @click="showSuccess1"> Show Success 1 </v-btn>
      </v-col>
      <v-col>
        <v-btn color="success" @click="showSuccess2">
          Show Alert Success
        </v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { ref } from "vue-demi";
import { useStore } from "@/store/app.pinia.js";

export default {
  name: "KitchenSink",
  components: {},
  setup() {
    const appStore = useStore();

    const alert2 = ref(false);
    const alert3 = ref(false);

    const showError1 = () => {
      appStore.setAlert({
        message: "Test Error Message",
        color: "error",
        timeout: 5000,
      });
    };

    const showError2 = () => {
      alert2.value = true;
    };

    const showSuccess1 = () => {
      appStore.setAlert({
        message: "Success Message!",
        color: "success",
        timeout: 5000,
      });
    };

    const showSuccess2 = () => {
      alert3.value = true;
    };

    return {
      appStore,
      showError1,
      showSuccess1,
      showError2,
      alert2,
      showSuccess2,
      alert3,
    };
  },
};
</script>
